import s from "@mobiltracker/schema-validation";

const directionSchema = s.enum(["TO", "FROM"]);

const executedRouteItemsSchema = s.object({
  executedRouteId: s.string(),
  vanId: s.number(),
  order: s.number(),
  studentId: s.number().nullable(),
  schoolId: s.number().nullable(),
  studentName: s.string().nullable(),
  schoolName: s.string().nullable(),
  direction: directionSchema.nullable(),
});
export type ExecutedRouteItemsType = s.infer<typeof executedRouteItemsSchema>;

const eventTypeSchema = s.enum([
  "START_ROUTE",
  "PICKING_UP_WAYTO",
  "ONBOARD_WAYTO",
  "ONBOARD_WAYFROM",
  "ARRIVED_WAYTO",
  "ARRIVED_WAYFROM",
  "ABSENT_WAYTO",
  "ABSENT_WAYFROM",
  "DROPPING_OFF_WAYFROM",
  "AUTOMATIC_DROPPING_OFF_WAYFROM",
  "AUTOMATIC_PICKING_UP_WAYTO",
  "END_ROUTE",
]);
export type EventType = s.infer<typeof eventTypeSchema>;

const executedRouteEventsSchema = s.object({
  eventId: s.number(),
  routeId: s.number(),
  studentId: s.number().nullable(),
  studentName: s.string().nullable(),
  schoolName: s.string().nullable(),
  type: eventTypeSchema,
  vanId: s.number(),
  serverTimestamp: s.coerce.date(),
  clientTimestamp: s.coerce.date(),
  executedRouteId: s.string(),
});
export type ExecutedRouteEventsType = s.infer<typeof executedRouteEventsSchema>;

export const basicExecutedRouteSchema = s.object({
  executedRouteId: s.string(),
  vanId: s.number(),
  vanName: s.string(),
  createDate: s.coerce.date(),
  routeTemplateName: s.string().nullable(),
});

export const fullExecutedRouteSchema = basicExecutedRouteSchema.and(
  s.object({
    routeItems: executedRouteItemsSchema.array(),
    routeEvents: executedRouteEventsSchema.array(),
  })
);
