import s, { InferSchema } from "@mobiltracker/schema-validation";

// Matching collection off App Scopes with VanEscola Api
// https://github.com/mobiltracker/vanescola-api/blob/master/VanEscola.Api/Authorization/AppScopes.cs
const ScopesSchema = s
  .union([
    s.literal("student:read"),
    s.literal("student:write"),
    s.literal("school:read"),
    s.literal("school:write"),
    s.literal("parent:read"),
    s.literal("parent:write"),
    s.literal("driver:read"),
    s.literal("driver:write"),
    s.literal("vans:read"),
    s.literal("vans:write"),
    s.literal("vanLocation:read"),
    s.literal("vanLocation:write"),
    s.literal("routes:read"),
    s.literal("routes:write"),
    s.literal("organization:read"),
    s.literal("organization:write"),
    s.literal("account:read"),
    s.literal("account:write"),
    s.literal("accountPassword:write"),
    s.literal("report:read"),
    s.literal("suggestion:read"),
    s.literal("suggestion:write"),
    s.literal("recurrences:read"),
    s.literal("recurrences:write"),
    s.literal("subscriptions:read"),
    s.literal("subscriptions:write"),
    s.literal("impersonate:read"),
    s.literal("impersonate:write"),
  ])
  .array();

export const ScopesApiResponseSchema = s.object({ scopes: ScopesSchema });

export type Scopes = InferSchema<typeof ScopesSchema>;
