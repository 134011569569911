import { Scopes } from "src/types/Scopes";
import { lazyPages } from "./lazyPages";

export type NavigationProps<URLProps, ComponentProps> = {
  urlProps: URLProps;
  componentProps?: ComponentProps;
};

export type Route<URLProps, ComponentProps> = {
  title: string;
  path: string;
  requiredAuth?: boolean;
  requiredScopes?: Scopes;
  lazyPage: React.LazyExoticComponent<
    (props: NavigationProps<URLProps, ComponentProps>) => JSX.Element
  >;
  fromPath: (params: Record<string, string | undefined>) => URLProps;
};

const makeRoute = <URLProps = undefined, Props = undefined>(
  r: Route<URLProps, Props>
) => r;
const makeSimpleRoute = <ComponentProps = undefined>(
  r: Omit<Route<undefined, ComponentProps>, "fromPath">
): Route<undefined, ComponentProps> => ({
  ...r,
  fromPath: () => undefined,
});

export const routes = {
  home: makeSimpleRoute({
    title: "Início",
    path: "/",
    lazyPage: lazyPages.HomePage,
  }),

  listRecurrences: makeSimpleRoute({
    title: "Cobranças Recorrentes",
    path: "/financas/recorrencias",
    lazyPage: lazyPages.ListRecurrencesPage,
  }),
  createRecurrence: makeSimpleRoute({
    title: "Criar Cobrança Recorrente",
    path: "/financas/recorrencias/nova",
    lazyPage: lazyPages.CreateRecurrencePage,
  }),

  listInstallments: makeSimpleRoute({
    title: "Finanças",
    path: "/financas",
    lazyPage: lazyPages.ListInstallments,
  }),
  installmentsByParent: makeRoute({
    title: "Faturas por Responsável",
    path: "/financas/faturas/responsavel/:id",
    fromPath: ({ id }) => ({ id: id ?? "" }),
    lazyPage: lazyPages.ListInstallmentsByParentPage,
  }),
  installmentsByMonth: makeRoute({
    title: "Faturas por Mês",
    path: "/financas/faturas/:month/:year",
    lazyPage: lazyPages.ListInstallmentsByMonthPage,
    fromPath: (params) => ({
      month: params.month
        ? parseInt(params.month, 10)
        : new Date().getMonth() + 1,
      year: params.year ? parseInt(params.year, 10) : new Date().getFullYear(),
    }),
  }),

  listStudents: makeSimpleRoute({
    title: "Estudantes",
    path: "/estudantes",
    lazyPage: lazyPages.ListStudentsPage,
  }),
  createStudent: makeSimpleRoute({
    title: "Cadastrar Estudante",
    path: "/estudantes/novo",
    lazyPage: lazyPages.CreateStudentPage,
    requiredScopes: ["student:write"],
  }),
  editStudent: makeRoute({
    title: "Editar Estudante",
    path: "/estudantes/:id",
    fromPath: ({ id }) => ({ id: id ? parseInt(id, 10) : 0 }),
    lazyPage: lazyPages.EditStudentPage,
  }),
  listParents: makeSimpleRoute({
    title: "Responsáveis",
    path: "/responsaveis",
    lazyPage: lazyPages.ListParentsPage,
  }),
  createParent: makeSimpleRoute({
    title: "Cadastrar Responsável",
    path: "/responsaveis/novo",
    lazyPage: lazyPages.CreateParentPage,
    requiredScopes: ["parent:write"],
  }),
  editParent: makeRoute({
    title: "Editar Responsável",
    path: "/responsaveis/:id",
    fromPath: ({ id }) => ({ id: id ?? "" }),
    lazyPage: lazyPages.EditParentPage,
  }),
  listSchools: makeSimpleRoute({
    title: "Escolas",
    path: "/escolas",
    lazyPage: lazyPages.ListSchoolsPage,
  }),
  createSchool: makeSimpleRoute({
    title: "Cadastrar Escola",
    path: "/escolas/nova",
    lazyPage: lazyPages.CreateSchoolPage,
    requiredScopes: ["school:write"],
  }),
  editSchool: makeRoute({
    title: "Editar Escola",
    path: "/escolas/:id",
    fromPath: ({ id }) => ({ id: id !== undefined ? parseInt(id, 10) : 0 }),
    lazyPage: lazyPages.EditSchoolPage,
  }),

  listDrivers: makeSimpleRoute({
    title: "Motoristas",
    path: "/motoristas",
    lazyPage: lazyPages.ListDriversPage,
  }),
  editDriver: makeRoute({
    title: "Editar Motorista",
    path: "/motoristas/:id",
    fromPath: ({ id }) => ({ id: id ?? "" }),
    lazyPage: lazyPages.DriverPage,
  }),
  createDriver: makeSimpleRoute({
    title: "Cadastrar Motorista",
    path: "/motoristas/novo",
    lazyPage: lazyPages.CreateDriverPage,
  }),

  listVans: makeSimpleRoute({
    title: "Vans",
    path: "/vans",
    lazyPage: lazyPages.ListVansPage,
  }),
  editVan: makeRoute({
    title: "Editar Van",
    path: "/vans/:id",
    fromPath: ({ id }) => ({ id: id !== undefined ? parseInt(id, 10) : 0 }),
    lazyPage: lazyPages.VanPage,
  }),
  createVan: makeSimpleRoute({
    title: "Cadastrar Van",
    path: "/vans/nova",
    lazyPage: lazyPages.CreateVanPage,
  }),

  listRoutes: makeSimpleRoute({
    title: "Rotas",
    path: "/rotas",
    lazyPage: lazyPages.ListRoutesPage,
  }),
  createRoute: makeSimpleRoute({
    title: "Cadastrar Rota",
    path: "/rotas/nova",
    lazyPage: lazyPages.CreateRoutePage,
  }),
  createRouteItems: makeSimpleRoute({
    title: "Montar Rota",
    path: "/rotas/nova/montagem",
    lazyPage: lazyPages.CreateRouteItemsPage,
  }),
  viewRoute: makeRoute({
    title: "Visualizar Rota",
    path: "/rotas/:id",
    fromPath: ({ id }) => ({
      id: id !== undefined ? parseInt(id, 10) : 0,
    }),
    lazyPage: lazyPages.ViewRoutePage,
  }),
  editRoute: makeRoute({
    title: "Editar Rota",
    path: "/rotas/:id/editar",
    fromPath: ({ id }) => ({
      id: id !== undefined ? parseInt(id, 10) : 0,
    }),
    lazyPage: lazyPages.EditRoutePage,
  }),

  listExecutedRoutes: makeSimpleRoute({
    title: "Histórico de Rotas",
    path: "/rotas/executadas",
    lazyPage: lazyPages.ListExecutedRoutesPage,
  }),
  viewExecutedRoute: makeRoute({
    title: "Rota",
    path: "/rotas/executadas/:id",
    fromPath: ({ id }) => ({ id: id ?? "" }),
    lazyPage: lazyPages.ViewExecutedRoutePage,
  }),
  listCurrentRoutes: makeSimpleRoute({
    title: "Rotas em Execução",
    path: "/rotas/atuais",
    lazyPage: lazyPages.ListCurrentRoutesPage,
  }),

  // <CompatURLs>
  // Compat URLs for the links inside the driver app to keep functioning.
  copyRoute: makeRoute({
    title: "Copiar Rota",
    path: "/rotas/:id/copia",
    fromPath: ({ id }) => ({
      id: id !== undefined ? parseInt(id, 10) : 0,
    }),
    lazyPage: lazyPages.CopyRoutePage,
  }),
  // <CompatURLs />

  profile: makeSimpleRoute({
    title: "Minha Conta",
    path: "/conta",
    lazyPage: lazyPages.ProfilePage,
  }),
  subscription: makeSimpleRoute({
    title: "Meu Plano",
    path: "/conta/plano",
    lazyPage: lazyPages.SubscriptionPage,
  }),

  login: makeSimpleRoute({
    title: "Login",
    path: "/login",
    requiredAuth: false,
    lazyPage: lazyPages.LoginPage,
  }),
  activateAccountNew: makeSimpleRoute({
    title: "Ativação de Conta",
    path: "/ativar-conta",
    requiredAuth: false,
    lazyPage: lazyPages.ActivationPage,
  }),
  recoverPassword: makeSimpleRoute({
    title: "Recuperação de Senha",
    path: "/recuperar-senha",
    requiredAuth: false,
    lazyPage: lazyPages.RecoverPasswordPage,
  }),
  resetPassword: makeSimpleRoute({
    title: "Redefinição de Senha",
    path: "/resetar-senha",
    requiredAuth: false,
    lazyPage: lazyPages.ResetPasswordPage,
  }),

  dev: makeSimpleRoute({
    title: "Dev Page",
    path: "/dev",
    lazyPage: lazyPages.DevPage,
  }),
  fakeauth: makeSimpleRoute({
    title: "Fake Auth",
    path: "/fakeauth",
    lazyPage: lazyPages.FakeAuthPage,
  }),
  driverResources: makeSimpleRoute({
    title: "Recursos para Motorista",
    path: "/recursos-motorista",
    lazyPage: lazyPages.DriverResources,
  }),
  parentResources: makeSimpleRoute({
    title: "Recursos para Clientes",
    path: "/recursos-pais",
    lazyPage: lazyPages.ParentResources,
  }),
  admin: makeSimpleRoute({
    title: "Admin",
    path: "/administration",
    lazyPage: lazyPages.AdminPage,
  }),
};
